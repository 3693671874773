import { API } from "utils";
import { Buffer } from 'buffer';

export const getImage = async (id) => {
  try {
    const response = await API({}, {}, { timeout: 60 }).get(`api/files/images/${id}`, {
      responseType: "arraybuffer",
    });
    return {
      success: true,
      payload: Buffer.from(response.data, "binary").toString("base64"),
    };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};
