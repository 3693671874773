import axios from "axios";
import axiosRetry from "axios-retry";
// import https from "https";
import { API_URL } from "config";
import { navigate } from "@reach/router";
import { toast } from 'react-toastify';

export const API = (opts = {}, optsHeader = {}, retryOptions = { timeout: 30, retries: 25, retryDelay: 1000 }) => {
  const DEFAULT_TIMEOUT = retryOptions.timeout !== undefined ? retryOptions.timeout : 30; // Request timeout
  const DEFAULT_RETRIES = retryOptions.retries !== undefined ? retryOptions.retries : 25;
  const DEFAULT_RETRY_DELAY = retryOptions.retryDelay !== undefined ? retryOptions.retryDelay : 1000; // Retry delay

  /*
  |--------------------------------------------------
  | Custom axios api
  |--------------------------------------------------
  */
  const Authorization = localStorage.getItem("user");
  const defaultOptions = {
    ...opts,
    timeout: 1000 * DEFAULT_TIMEOUT,
    headers: {
      Authorization,
      // Accept: "*/*",
      // "Accept-Enconding": "gzip, deflate, br",
      // Connection: "keep-alive",
      // "Cache-Control": "no-cache",
      // "Postman-Token": "42e6c291-9a09-c29f-f28f-11872e2490a5",
      // "Content-Type": "multipart/form-data",
      ...optsHeader,
    },
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    trailingSlash: true,
  };

  const axiosApi = axios.create({
    baseURL: API_URL,
    // httpsAgent is not needed as client use system default, until external certificates provided
    // httpsAgent: new https.Agent({
    //   rejectUnauthorized: false,
    // }),
    ...defaultOptions,
    // adapter: cache.adapter,
  });
  axios.trailingSlash = true;


  // Auto retry failed/timedout requests
  axiosRetry(axiosApi, {
    retries: DEFAULT_RETRIES,
    retryDelay: (retryCount) => {
      return DEFAULT_RETRY_DELAY || axiosRetry.exponentialDelay(retryCount); // Delay between retries
    },
    shouldResetTimeout: true, // Reset timeout for each retry
    retryCondition: (error) => {
      console.log('retryCondirion', DEFAULT_RETRIES, retryOptions)
      // Retry on network errors or timeout errors
      return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.toJSON().message === 'Network Error' || ![403, 404].includes(error.response?.status);
    },
  });

  axiosApi.interceptors.response.use(null, (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      localStorage.removeItem("user");
      navigate(`/login?redirect=${window.location.href}`);
    }
    else {
      if (error.response?.data?.errors[0]?.msg) {
        toast.error(error.response?.data?.errors[0]?.msg.toString(), {
          position: "top-left", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "light"
        });
      }
      else if (error.response?.data?.errors[0]) {
        toast.error(error.response?.data?.errors[0].toString(), {
          position: "top-left", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "light"
        });
      }
      else {
        toast.error(error.toString(), {
          position: "top-left", autoClose: 5000, hideProgressBar: true, closeOnClick: true,
          pauseOnHover: true, progress: undefined, theme: "light"
        });
      }
    }
    return Promise.reject(error);
  });
  return axiosApi;
};
