/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Input2, DropDown2, ToggleButton, CheckBox } from "components";
import { useInput } from "hooks";
import { useMainContext } from "contexts";
import AutocompleteFreeText from "components/AutocompleteFreeText";
import { SAVE_OFFER_PREDICTIVE_TEXT } from "config";
import { isNumber, isString } from "lodash";

export const OfferSaveTextBuilder = ({
  className,
  value,
  existingOfferText,
  onChange = () => {},
  onStartChange = () => {}
}) => {
  const {currentInfo}= useMainContext();
  const start = useInput();
  const end = useInput("");
  const upto = useInput("");
  const extra = useInput("");
  const amount = useInput("");
  const type = useInput("%");
  const start_price = useInput();
  const end_price = useInput("");
  const bulk_amount = useInput("");
  const perc_included = useInput("");
  const [bulkCalculator, setBulkCalculator] = useState(false);
  const [calculatorType, setCalculatorType] = useState(false);
  const appliesToEach = useInput(false);
  const typeData = [
    { id: "%", title: "%" },
    { id: "$", title: "$" },
  ];
  const freeText = useInput("");

  const handleUpdateValue = (start, end, amount, type, free_text, each, upto, extra) => {
    let placeholder = ''; 
    if (upto) {
      placeholder = 'up to'
    } else if (extra){
      placeholder = 'an extra'
    }
    console.log(placeholder)
    switch (type) {
      case "%":

          if(end) {
            onChange(`save${placeholder ? ` ${placeholder}` : ''} ${start}% - ${end}%${amount 
              ? ` ${amount}` : ''}${each 
              ? ' each' : ''}${free_text 
              ? ` ${free_text}` : ''}`);
          }else{
            onChange(`save${placeholder ? ` ${placeholder}` : ''} ${start}%${amount
              ? ` ${amount}` : ''}${each 
              ? ' each' : ''}${free_text 
              ? ` ${free_text}` : ''}`);
          }

        break;
      case "$":

          if(end) {
            onChange(`save${placeholder ? ` ${placeholder}` : ''} $${start} - $${end}${amount
              ? ` ${amount}` : ''}${each 
              ? ' each' : ''}${free_text 
              ? ` ${free_text}` : ''}`);
          }else{
            onChange(`save${placeholder ? ` ${placeholder}` : ''} $${start}${amount
              ? ` ${amount}` : ''}${each 
              ? ' each' : ''}${free_text 
              ? ` ${free_text}` : ''}`);
          }

        break;
      default:
        break;
    }
  };

  const handleUnitCalculator = (
    start_price = existingOfferText,
    end_price = 0,
    bulk_amount = 1,
    perc_included,
    free_text,
    each,
    upto,
    extra
  ) => {
    let placeholder = ''
    if (upto) {
      placeholder = 'up to'
    } else if (extra) {
      placeholder = 'an extra'
    }
    if (!perc_included) {
      onChange(`save${placeholder ? ` ${placeholder}` : ''} $${((start_price - end_price) * bulk_amount).toFixed(2)
        } ${each ? 'each' : ''} ${free_text ? free_text : ''}`);
    } else {
      console.log(typeof start_price,typeof end_price, bulk_amount, parseInt(start_price) - parseInt(end_price))
      onChange(`save${placeholder ? ` ${placeholder}` : ''} $${((start_price - end_price) * bulk_amount).toFixed(2)
        } (${(((start_price - end_price) / start_price) * 100).toFixed()}%) ${each
          ? 'each' : ''} ${free_text ? free_text : ''}`);
    }
  }

  const handleBulkCalculator = (
    start_price = existingOfferText,
    end_price = 0,
    bulk_amount = 1,
    perc_included,
    free_text,
    each,
    upto,
    extra
  ) => {
    let placeholder = ''
    if (upto) {
      placeholder = 'up to'
    } else if (extra) {
      placeholder = 'an extra'
    }
    if (!perc_included) {
      onChange(`save${placeholder ? ` ${placeholder}` : ''} $${((start_price * bulk_amount) - end_price).toFixed(2)
        } ${each ? 'each' : ''} ${free_text ? free_text : ''}`);
    } else {
      onChange(`save${placeholder ? ` ${placeholder}` : ''} $${((start_price * bulk_amount) - end_price).toFixed(2)
        } (${((((start_price * bulk_amount) - end_price) / (start_price * bulk_amount)) * 100).toFixed()}%) ${each
          ? 'each' : ''} ${free_text ? free_text : ''}`);
    }
  }

  const handleCalculatorToggle = (e) => {
    setBulkCalculator(e);
    if (!e) {
      handleUpdateValue(start.value, end.value, amount.value, type.value, freeText.value, appliesToEach.value, upto.value, extra.value);
    } else if (e) {
      if (calculatorType === 'unit')
        handleUnitCalculator(start_price.value, end_price.value, bulk_amount.value,perc_included.value, freeText.value, appliesToEach.value, upto.value, extra.value)
      else {
        handleBulkCalculator(start_price.value, end_price.value, bulk_amount.value, perc_included.value, freeText.value, appliesToEach.value, upto.value, extra.value)
      }
    }
  }

  useEffect(() => {
    if (!currentInfo?.offer_text){
      start_price.setValue(existingOfferText);
      start.setValue(existingOfferText);
      onChange(`save ${existingOfferText}%`);
    }
  }, [existingOfferText])

  useEffect(() => {
    if (value && !start.value) {
      let clean_value = value.replace('save ', '');
      if (clean_value.includes('up to')){
        upto.setValue(true);
        clean_value = clean_value.replace('up to', '');
      } else if(clean_value.includes('an extra')) {
        extra.setValue(true);
        clean_value = clean_value.replace('an extra', '');
      }
      if (clean_value.includes('each')) {
        appliesToEach.setValue(true);
        clean_value = clean_value.replace('each', '');
      }
      clean_value = clean_value.replace(' - ', '-');
      clean_value = clean_value.replace(' ', '*');

      let first_split = clean_value.split("*");
      let second_split = first_split[0].replace(/\s/g, '').split("-");
      
      const assumed_type = first_split[0].indexOf("$") > -1 ? "$" : "%";
      if(first_split.length > 1 && second_split.length > 1) {
        start.setValue(second_split[0].replace(assumed_type, ''));
        end.setValue(second_split[1].replace(assumed_type, ''));
        type.setValue(assumed_type);
        if (isNumber(first_split[1])){
          amount.setValue(first_split[1]);
        } else {
          if (isString(first_split[1])){
            let splits = first_split[1].split('  ');
            if (!isNaN(parseInt(splits[0]))){
              amount.setValue(splits[0]);
              freeText.setValue(splits[1]);
            } else {
              freeText.setValue(first_split[1]);
            }
          }
        }
      } else if (first_split.length > 1) {
        start.setValue(second_split[0].replace(assumed_type, ''));
        end.setValue("");
        type.setValue(assumed_type);
        if (isNumber(first_split[1])){
          amount.setValue(first_split[1]);
        } else {
          if (isString(first_split[1])){
            let splits = first_split[1].split('  ');
            if (!isNaN(parseInt(splits[0]))){
              amount.setValue(splits[0]);
              freeText.setValue(splits[1]);
            } else {
              freeText.setValue(first_split[1]);
            }
          }
        }
      } else if (first_split.length > 1) {
        start.setValue(second_split[0].replace(assumed_type, ''));
        end.setValue("");
        type.setValue(assumed_type);
        if (isNumber(first_split[1])){
          amount.setValue(first_split[1]);
        } else {
          if (isString(first_split[1])){
            let splits = first_split[1].split('  ');
            if (!isNaN(parseInt(splits[0]))){
              amount.setValue(splits[0]);
              freeText.setValue(splits[1]);
            } else {
              freeText.setValue(first_split[1]);
            }
          }
        }
      } else if (second_split.length > 1) {
        start.setValue(second_split[0].replace(assumed_type, ''));
        end.setValue(second_split[1].replace(assumed_type, ''));
        type.setValue(assumed_type);
        amount.setValue("");
      } else {
        start.setValue(second_split[0].replace(assumed_type, ''));
        end.setValue("");
        type.setValue(assumed_type);
        amount.setValue("");
      }
    }
    console.log(value)
    return () => {};
  }, [value]);

  return (
    <BuilderContainer className={`${className} mt-1`}>
      <Title>Offer Text Builder</Title>
      <ToggleButton 
        label="Calculator"
        value={bulkCalculator} 
        onChange={(e) => handleCalculatorToggle(e)}
        className="w-100" />
      {bulkCalculator &&
        <Container>
          <div style={{ width: 65, marginRight: 10 }}>
            <Input2
              label="Start Price"
              value={start_price.value}
              onChange={(e) => {
                start_price.setValue(e);
              }}
            />
          </div>
          <div style={{ width: 65, marginRight: 10 }}>
            <Input2
              label="End Price"
              value={end_price.value}
              onChange={(e) => {
                end_price.setValue(e);
              }}
            />
          </div>
          <div style={{ width: 65, marginRight: 10 }}>
            <Input2
              label="Amount"
              value={bulk_amount.value}
              onChange={(e) => {
                bulk_amount.setValue(e);
              }}
            />
          </div>

          <div className="d-flex flex-column m-auto">
            <ToggleButton
              label="Include %"
              className="w-100 mb-1"
              value={perc_included.value}
              onChange={e => perc_included.setValue(e)}
              />
            <ToggleButton
              label="Unit Calculator"
              className="w-100 mb-1"
              onChange={e => {
                setCalculatorType('unit');
                handleUnitCalculator(
                  start_price.value,
                  end_price.value,
                  bulk_amount.value,
                  perc_included.value,
                  freeText.value,
                  appliesToEach.value,
                  upto.value,
                  extra.value
                  );
                }}
                />
            <ToggleButton
              label="Bulk Calculator"
              className="w-100"
              onChange={e => {
                setCalculatorType('bulk');
                handleBulkCalculator(
                  start_price.value,
                  end_price.value,
                  bulk_amount.value,
                  perc_included.value,
                  freeText.value,
                  appliesToEach.value,
                  upto.value,
                  extra.value
                );
              }}
            />
          </div>
        </Container>}
      {!bulkCalculator && <div>
      <Container>
        <div style={{ width: 70 }}>
          <Input2
            label="Start"
            value={start.value}
            onChange={(e) => {
              start.setValue(e);
              type.value === "$" && onStartChange(e);
              handleUpdateValue(e, end.value, amount.value, type.value, freeText.value, appliesToEach.value, upto.value, extra.value);
            }}
          />
        </div>
        <div className="mt-auto mb-1 p-2 text">-</div>
        <div style={{ width: 70, marginRight:10 }}>
          <Input2
            label="End"
            value={end.value}
            onChange={(e) => {
              end.setValue(e);
              handleUpdateValue(start.value, e, amount.value, type.value, freeText.value, appliesToEach.value, upto.value, extra.value);
            }}
          />
        </div>

        <div className="ml-auto" >
          <DropDown2
            label="Type"
            data={typeData}
            width={60}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(start.value, end.value, amount.value, e, freeText.value, appliesToEach.value, upto.value, extra.value);
            }}
          />
        </div>

      </Container>
      <Container>
        <div className="w-100">
            <Input2
              label="Amount"
              value={amount.value}
              onChange={(e) => {
                amount.setValue(e.replace(" ", ""));
                handleUpdateValue(start.value, end.value, e, type.value, freeText.value, appliesToEach.value, upto.value, extra.value);
              }}
            />
        </div>
      </Container>
      </div>
      }
      <Container className="d-flex flex-column">
        <div className="d-flex">
          <ToggleButton
            label="up to"
            className="w-100 mb-1"
            value={upto.value}
            onChange={e => {
              upto.setValue(e)
              if (e) {
                extra.setValue(false);
              }
              if (bulkCalculator) {
                if (calculatorType === 'unit') {
                  handleUnitCalculator(
                    start_price.value,
                    end_price.value,
                    bulk_amount.value,
                    perc_included.value,
                    freeText.value,
                    appliesToEach.value,
                    e,
                    false
                  )
                } else {
                  handleBulkCalculator(
                    start_price.value,
                    end_price.value,
                    bulk_amount.value,
                    perc_included.value,
                    freeText.value,
                    appliesToEach.value,
                    e,
                    false
                  )
                }
            } else {
              handleUpdateValue(start.value, end.value, amount.value, type.value, freeText.value, appliesToEach.value, e, false);
            }}}
          />
          <ToggleButton
            label="an extra"
            className="w-100"
            value={extra.value}
            onChange={e => {
              extra.setValue(e);
              if (e) {
                upto.setValue(false);
              }
              if (bulkCalculator) {
                if (calculatorType === 'unit') {
                  handleUnitCalculator(
                    start_price.value,
                    end_price.value,
                    bulk_amount.value,
                    perc_included.value,
                    freeText.value,
                    appliesToEach.value,
                    false,
                    e,
                  )
                } else {
                  handleBulkCalculator(
                    start_price.value,
                    end_price.value,
                    bulk_amount.value,
                    perc_included.value,
                    freeText.value,
                    appliesToEach.value,
                    false,
                    e,
                  )
                }
            } else {
              handleUpdateValue(start.value, end.value, amount.value, type.value, freeText.value, appliesToEach.value, false, e);
            }}}
          />
        </div>
        <CheckBox
          value={appliesToEach.value}
          onChange={e => {
            appliesToEach.setValue(e);
            console.log(e);
            if (bulkCalculator) {
              if (calculatorType === 'unit') {
                handleUnitCalculator(
                  start_price.value,
                  end_price.value,
                  bulk_amount.value,
                  perc_included.value,
                  freeText.value,
                  e,
                  upto.value,
                  extra.value
                )
              } else {
                handleBulkCalculator(
                  start_price.value,
                  end_price.value,
                  bulk_amount.value,
                  perc_included.value,
                  freeText.value,
                  e,
                  upto.value,
                  extra.value
                )
              }
            } else {
              handleUpdateValue(start.value, end.value, amount.value, type.value, freeText.value, e, upto.value, extra.value);
            }
          }}
        >
          Applies To Each
        </CheckBox>
      </Container>
      <Container>
        <AutocompleteFreeText
          className="w-100"
          label='Free Text'
          value={freeText.value}
          onChange={e => {
            freeText.setValue(e);
            console.log(e)
            if (bulkCalculator) {
              if (calculatorType === 'unit') {
                handleUnitCalculator(
                  start_price.value,
                  end_price.value,
                  bulk_amount.value,
                  perc_included.value,
                  e,
                  appliesToEach.value,
                  upto.value,
                  extra.value
                )
              } else {
                handleBulkCalculator(
                  start_price.value,
                  end_price.value,
                  bulk_amount.value,
                  perc_included.value,
                  e,
                  appliesToEach.value,
                  upto.value,
                  extra.value
                )
              }
            } else {
              handleUpdateValue(start.value, end.value, amount.value, type.value, e, appliesToEach.value, upto.value, extra.value);
            }
          }}
          data={SAVE_OFFER_PREDICTIVE_TEXT}
        />
      </Container>
    </BuilderContainer>
  );
};

const BuilderContainer = styled.div`
  padding:8px 0;
  .text {
    font-size:12px;
  }
`;

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
  margin:2px 0;
`;
